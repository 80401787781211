/** @typedef {import("./m-flyout-list-topics").default} MFlyoutListTopics */

class MTopics {
	/**
	 * @param {HTMLDivElement} element
	 */
	constructor(element) {
		/** @type {HTMLScriptElement} */
		const dataElement = element.querySelector('.m-topics__data');

		if (dataElement) {
			/** @type {MFlyoutListTopics} */
			this.mFlyoutList = document.createElement('m-flyout-list-topics');

			/** @type {HTMLHeadingElement} */
			this.headingElement = element.querySelector('h2');

			this.aThumbPersonElements = element.querySelectorAll('.a-thumb-topic');

			// Event listener
			this.headingElement?.setAttribute('role', 'button');
			this.headingElement?.addEventListener('click', () => {
				this.mFlyoutList.showModal();
			});
			this.aThumbPersonElements.forEach(/** @param {HTMLAnchorElement} aThumbPersonElement */ (aThumbPersonElement) => {
				aThumbPersonElement.setAttribute('role', 'button');
				aThumbPersonElement.addEventListener('click', (event) => {
					event.preventDefault();
					this.mFlyoutList.setAttribute('selected-item', aThumbPersonElement.dataset.slug);
					this.mFlyoutList.showModal();
				});
			});

			// Init
			this.mFlyoutList.data = JSON.parse(dataElement.innerText);
			element.appendChild(this.mFlyoutList);
		}
	}
}

document.querySelectorAll('.m-topics:not([data-variant="standalone"])').forEach((_) => new MTopics(_));
