/**
 * @typedef {Object} Item
 * @property {String} slug
 * @property {String} title
 * @property {String} teaser html string
 * @property {String} svg svg code
 * @see public/site/snippets/m-topics.php
 */

import { html, nothing } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import MFlyoutList from './m-flyout-list.js';

import {
	showMore as showMoreTranslation,
} from '../foundation/languages.js';

const { lang } = document.documentElement;

const translations = {
	showMore: showMoreTranslation[lang] ?? showMoreTranslation.de,
};

class MFlyoutListTopics extends MFlyoutList {
	// Actions

	renderNav() {
		const { selectedItem } = this;
		const { heading, items } = this.data;

		return html`
			<h2 class="a-heading">${heading}</h2>
			<ul>
				${items?.map(/** @param {Item} item */ (item) => html`
					<li>
						<button @click="${() => this.onItemClick(item.slug)}" data-selected="${selectedItem === item ? null : nothing}">
							<div>
								<div class="a-thumb-topic" data-size="small">
									<figure>
										${unsafeHTML(item?.svg)}
									</figure>
									${item?.title}
								</div>
							</div>
						</button>
					</li>
				`)}
			</ul>
		`;
	}

	renderItem(selectedItem) {
		return html`
			<figure class="symbol">
				${unsafeHTML(selectedItem?.svg)}
			</figure>
			<div>
				<h2>${selectedItem?.title}</h2>
				<div class="m-text" data-size="small">
					${unsafeHTML(selectedItem?.teaser)}
				</div>
				<a class="a-button" href="${selectedItem?.url}">${translations.showMore}</a>
			</div>
		`;
	}
}

// Define the custom element
if (!customElements.get('m-flyout-list-topics')) {
	customElements.define('m-flyout-list-topics', MFlyoutListTopics);
}

export default MFlyoutListTopics;
