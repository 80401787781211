/**
 * @typedef {Object} Person
 * @property {Boolean} lead
 * @property {String} slug
 * @property {String} name
 * @property {String} occupation
 * @property {String} roles
 * @property {String} email
 * @property {String} tel
 * @property {String} vita html string
 * @property {String} url
 * @property {?Object} portraitImage
 * @property {?Object} teaserImage
 * @see public/site/models/person.php:217 (toJsonItem)
 */

import { html, nothing } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import MFlyoutList from './m-flyout-list.js';
import {
	tel as telTranslation,
	personProfile as personProfileTranslation,
} from '../foundation/languages.js';

const { lang } = document.documentElement;

const translations = {
	tel: telTranslation[lang] ?? telTranslation.de,
	personProfile: personProfileTranslation[lang] ?? personProfileTranslation.de,
};

class MFlyoutListPersons extends MFlyoutList {
	// Data
	get items() {
		const persons = [];
		this.data?.personGroups?.forEach((personGroup) => {
			personGroup.forEach((person) => {
				persons.push(person);
			});
		});
		this.data?.persons?.forEach((person) => {
			persons.push(person);
		});
		return persons;
	}

	// Actions

	renderPerson(person) {
		const { selectedItem } = this;

		return html`
			<li>
				<button @click="${() => this.onItemClick(person.slug)}" data-selected="${selectedItem === person ? null : nothing}">
					<div>
						<div class="a-thumb-person" data-size="small" data-variant="${person.lead ? 'lead' : nothing}">
							<img
								src="${person?.portraitImage?.src}"
								srcset="${person?.portraitImage?.srcset}"
								width="${person?.portraitImage?.width}"
								height="${person?.portraitImage?.height}"
								alt=""
							>
							<div>
								<strong>${person.name}</strong>
								<em>${person.occupation}</em>
							</div>
						</div>
					</div>
				</button>
			</li>
		`;
	}

	renderNav() {
		const { heading, personGroups, persons } = this.data;

		return html`
			<h2 class="a-heading">${heading}</h2>
			<ul>
				${persons?.map(/** @param {Person} person */ (person) => this.renderPerson(person))}
				${personGroups?.map((_persons) => html`
					<li>
						<ul>
							${_persons?.map(/** @param {Person} person */ (person) => this.renderPerson(person))}
						</ul>
					</li>
				`)}
			</ul>
		`;
	}

	renderItem(selectedItem) {
		return html`
			<img
				src="${selectedItem?.teaserImage?.src}"
				srcset="${selectedItem?.teaserImage?.srcset}"
				width="${selectedItem?.teaserImage?.width}"
				height="${selectedItem?.teaserImage?.height}"
				sizes="(max-width: 600px) 100vw, 600px"
				alt=""
			>
			<div>
				<h2>${selectedItem?.name}</h2>
				<div class="m-text" data-size="small">
					<p>${unsafeHTML(selectedItem?.roles)}</p>
					<p>
						${translations.tel}: <a href="tel:${selectedItem?.tel}">${selectedItem?.tel}</a><br>
						<a href="mailto:${selectedItem?.email}">${selectedItem?.email}</a><br>
					</p>
					<p>${unsafeHTML(selectedItem?.vita)}</p>
				</div>
				<a class="a-button" href="${selectedItem?.url}">${translations.personProfile}</a>
			</div>
		`;
	}
}

// Define the custom element
if (!customElements.get('m-flyout-list-persons')) {
	customElements.define('m-flyout-list-persons', MFlyoutListPersons);
}

export default MFlyoutListPersons;
