class MQuotes {
	/** @type {null|number} */
	currentActiveListElementIndex = null;

	/** @param {HTMLDivElement} element */
	constructor(element) {
		this.element = element;

		this.navButtonElements = element.querySelectorAll(':scope > nav button');

		this.listElement = element.querySelector(':scope > ul');
		this.listItemElements = this.listElement.querySelectorAll(':scope > li');

		// Event Listeners
		this.listElement.addEventListener('scroll', this.onListScroll.bind(this));
		this.navButtonElements.forEach((navButtonElement) => {
			navButtonElement.addEventListener('click', this.onNavElementClick.bind(this));
		});

		// Init
		this.currentActiveListElementIndex = this.getCurrentActiveListElementIndex();
		this.updateNavElements();
		this.updateListItemElements();
	}

	/** @returns {number} */
	getCurrentActiveListElementIndex() {
		const { listElement, listItemElements } = this;
		const { scrollLeft } = listElement;

		// Find the index of the child that is closest to the current scroll position
		let closestChildIndex = 0;
		let smallestDifference = Infinity;

		/** @param {HTMLLIElement} child */
		listItemElements.forEach((child, index) => {
			const difference = Math.abs(scrollLeft - child.offsetLeft);
			if (difference < smallestDifference) {
				smallestDifference = difference;
				closestChildIndex = index;
			}
		});

		return closestChildIndex;
	}

	updateNavElements() {
		const {
			currentActiveListElementIndex,
			navButtonElements,
		 } = this;
		navButtonElements.forEach((navButtonElement, key) => {
			navButtonElement.toggleAttribute('data-selected', currentActiveListElementIndex === key);
			if (currentActiveListElementIndex === key) {
				navButtonElement.setAttribute('aria-pressed', 'true');
			} else {
				navButtonElement.removeAttribute('aria-pressed');
			}
		});
	}

	updateListItemElements() {
		const {
			listItemElements,
			currentActiveListElementIndex,
	  } = this;
		listItemElements.forEach((listItemElement, key) => {
			listItemElement.toggleAttribute('inert', key !== currentActiveListElementIndex);
		});
	}

	/** @param {PointerEvent} event */
	onNavElementClick(event) {
		const {
			listItemElements,
		} = this;

		/** @type {HTMLLIElement} */
		const navListItemElement = event.target.parentElement;
		const index = [...navListItemElement.parentElement.children].indexOf(navListItemElement);
		const listItemElement = listItemElements[index];

		this.listElement.scroll({
			left: listItemElement.offsetLeft,
		});
	}

	onListScroll() {
		this.currentActiveListElementIndex = this.getCurrentActiveListElementIndex();
		this.updateNavElements();
		this.updateListItemElements();
	}
}

document.querySelectorAll('.m-quotes').forEach((element) => new MQuotes(element));
