/** @typedef {import("../types/kirby-api-response.js").KirbyApiCollectionResponse} KirbyApiCollectionResponse */

class MFilterPersons {
	/** @type {HTMLFormElement} */
	element;

	/** @type {?HTMLDivElement} */
	personsInfoElement;

	/** @type {HTMLDivElement} */
	personsListContainerElement;

	/** @type {HTMLUListElement} */
	personsListElement;

	/** @type {?string[]} */
	visibleSlugs;

	/** @type {?AbortController} */
	fetchAbortController;

	/** @type {?string} Language code from document element */
	lang;

	/** @param {HTMLFormElement} element */
	constructor(element) {
		// Elements
		this.element = element;
		this.personsListContainerElement = document.querySelector('.o-persons__list-container');
		this.personsListElement = this.personsListContainerElement.querySelector('ul');

		// Class variables
		this.lang = document.documentElement.lang;

		// Variables
		let debounceTimeout;

		// Events
		element.querySelector('#competency')?.addEventListener('change', () => {
			this.onSubmit();
		});
		element.querySelector('#q')?.addEventListener('input', () => {
			clearTimeout(debounceTimeout);
			debounceTimeout = setTimeout(() => {
				this.onSubmit();
			}, 100); // 100ms delay
		});
		element.addEventListener('submit', this.onSubmit.bind(this));

		// Init
		this.onSubmit();
	}

	get formData() {
		return new FormData(this.element);
	}

	/**
	 * @returns {Promise<KirbyApiCollectionResponse>}
	 * @throws Error
	 */
	fetch() {
		const { element } = this;

		this.fetchAbortController?.abort();
		this.fetchAbortController = new AbortController();

		return fetch(element.action, {
			method: 'post',
			body: this.formData,
			signal: this.fetchAbortController.signal,
			headers: {
				'x-language': document.documentElement.lang,
			},
		}).then(async (response) => {
			/** @type {KirbyApiCollectionResponse} */
			const data = await response.json();
			if (data.status === 'error') {
				throw new Error(data?.message);
			}
			return data;
		});
	}

	showInfo(message) {
		if (typeof this.personsInfoElement !== 'object') {
			this.personsInfoElement = document.createElement('div');
			this.personsInfoElement.classList.add('o-persons__info');
			this.personsListContainerElement.appendChild(this.personsInfoElement);
		}
		this.personsInfoElement.innerText = message;
	}

	filterPersons() {
		[...this.personsListElement.children].forEach((/** @type {HTMLLIElement} */ liElement) => {
			const showElement = Array.isArray(this.visibleSlugs)
				? this.visibleSlugs.includes(liElement.id)
				: true;
			liElement.toggleAttribute('hidden', !showElement);
		});
	}

	// Event Listeners
	/** @param {?SubmitEvent} event */
	async onSubmit(event) {
		event?.preventDefault();

		try {
			if (this.formData.get('q').trim().length < 2 && this.formData.get('competency').length === 0) {
				// reset visible slugs, when query and competency select is empty
				this.visibleSlugs = null;
			} else {
				const { data, status, message } = await this.fetch();
				if (status === 'info') {
					this.visibleSlugs = [];
					this.showInfo(message);
				} else {
					this.visibleSlugs = data?.map((item) => item.slug);
					console.log(this.visibleSlugs);
				}
			}

			const hasVisibleSlugs = this.visibleSlugs?.length !== 0;

			this.personsInfoElement?.toggleAttribute('hidden', hasVisibleSlugs);
			this.personsListElement?.toggleAttribute('hidden', !hasVisibleSlugs);
			this.filterPersons();
		} catch (/** @type {Error} */ error) {
			if (error.name !== 'AbortError') {
				console.error(error);
				alert(error?.message ?? 'Fatal error');
			}
		}
	}
}

const element = document.querySelector('.m-filter-persons');
if (element) {
	// eslint-disable-next-line no-new
	new MFilterPersons(element);
}
