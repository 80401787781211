class MNavigation {
	/** @param {HTMLDivElement} element */
	constructor(element) {
		const buttonHamburgerElement = element.querySelector('.a-nav-link[data-type="hamburger"]');
		this.element = element;
		this.listElement = element.querySelector('.m-navigation__list');

		// Event Listeners
		buttonHamburgerElement?.addEventListener('click', this.toggleNav.bind(this));

		// Init
		this.showSelectedNavLinkForSecondaryNavigation();

		document.addEventListener('keydown', (event) => {
			if (event.key === 'Escape') {
				this.hideNav();
			}
		});
	}

	showSelectedNavLinkForSecondaryNavigation() {
		const { element } = this;

		if (element.getAttribute('data-type') === 'secondary') {
			const aNavLinkSelectedElement = element.querySelector('li:has(.a-nav-link[data-selected])');
			if (aNavLinkSelectedElement) {
				const isInView = (aNavLinkSelectedElement.offsetLeft + aNavLinkSelectedElement.offsetWidth)
					> element.offsetWidth;
				if (aNavLinkSelectedElement && isInView) {
					element.scroll({
						left: aNavLinkSelectedElement.offsetLeft - 40,
						behavior: 'smooth',
					});
				}
			}
		}
	}

	toggleNav() {
		const { element } = this;
		element.toggleAttribute('data-open', !element.hasAttribute('data-open'));
	}

	hideNav() {
		const { element } = this;
		element.toggleAttribute('data-open', false);
	}
}

document.querySelectorAll('.m-navigation').forEach((_) => new MNavigation(_));
