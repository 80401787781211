// This file is automatically generated by generate-languages-js.php
// Last build: 2024-11-27T09:56:14+01:00

export const close = {
	de: 'schließen',
	en: 'close',
};

export const showMore = {
	de: 'Zeige mehr',
	en: 'Show more',
};

export const filterPersonsResultsNone = {
	de: 'Für Ihre Suche konnte leider keine passende Person gefunden werden.',
	en: 'Sorry, no matching person could be found for your search',
};

export const articleDate = {
	de: 'Stand: {date}',
	en: 'Date: {date}',
};

export const eventCanceled = {
	de: 'abgesagt',
	en: 'Canceled',
};

export const eventPostponed = {
	de: 'verschoben',
	en: 'Postponed',
};

export const eventSpeakers = {
	de: ['', 'Referent', 'Referenten'],
	en: ['', 'Speaker', 'Speakers'],
};

export const mPersonGroupsHeading = {
	de: ['', 'Ihr Spezialist', 'Ihre Spezialisten'],
	en: ['', 'Your specialist', 'Your specialists'],
};

export const mTopicsHeading = {
	de: ['', 'Thema', 'Themen'],
	en: ['', 'Topic', 'Topics'],
};

export const topicsOf = {
	de: 'Themen von {topic}',
	en: 'Topics of {topic}',
};

export const mArticlesHeading = {
	de: ['', 'Aktueller Beitrag', 'Aktuelle Beiträge'],
	en: ['', 'Recent post', 'Recent posts'],
};

export const mArticlesShowAll = {
	de: 'Alle Beiträge',
	en: 'All articles',
};

export const mPublicationsShowAll = {
	de: 'Alle Veröffentlichungen',
	en: 'All publications',
};

export const mEventsShowAll = {
	de: 'Alle Veranstaltungen',
	en: 'All events',
};

export const mTopicsShowAll = {
	de: 'Zeige alle',
	en: 'Show all',
};

export const vita = {
	de: 'Kurzlebenslauf',
	en: 'Vita',
};

export const services = {
	de: 'Leistungen',
	en: 'Services',
};

export const servicesOf = {
	de: 'Leistungen im Bereich {topic}',
	en: 'Services in {topic}',
};

export const tel = {
	de: 'Telefon',
	en: 'Telephone',
};

export const publications = {
	de: 'Veröffentlichungen',
	en: 'Publications',
};

export const references = {
	de: 'Referenzen',
	en: 'References',
};

export const talks = {
	de: 'Veranstaltungen',
	en: 'Events',
};

export const talksUpcoming = {
	de: 'Kommende Veranstaltungen',
	en: 'Upcoming events',
};

export const competencyTeam = {
	de: 'Kompetenzteam',
	en: 'Competency team',
};

export const currentArticlesOf = {
	de: 'Aktuelle Beiträge zu {topic}',
	en: 'Current articles on {topic}',
};

export const currentArticlesFrom = {
	de: 'Beiträge von {person}',
	en: 'Articles from {person}',
};

export const currentEventsOf = {
	de: 'Veranstaltungen zu {competency}',
	en: 'Events for {competency}',
};

export const eventsOf = {
	de: 'Veranstaltungen zu {competency}',
	en: 'Events of {competency}',
};

export const eventsUpcoming = {
	de: 'Kommende Veranstaltungen',
	en: 'Upcoming events',
};

export const eventsPast = {
	de: 'Vergangene Veranstaltungen',
	en: 'Past events',
};

export const news = {
	de: 'Aktuelles',
};

export const publicationsFrom = {
	de: 'Alle Veröffentlichungen von {person}',
	en: 'All publications by {person}',
};

export const currentArticles = {
	de: 'Aktuelle Beiträge',
	en: 'Current articles',
};

export const contact = {
	de: 'Kontakt',
	en: 'Contact',
};

export const location = {
	de: 'Standort',
	en: 'Location',
};

export const locationShow = {
	de: 'Standort anzeigen',
};

export const locations = {
	de: 'Standorte',
	en: 'Locations',
};

export const locationsHomeHeading = {
	de: 'Unsere Standorte',
};

export const competencies = {
	de: 'Kompetenzen',
	en: 'competencies',
};

export const competenciesHomeHeading = {
	de: 'Unsere Kompetenzen',
};

export const quotes = {
	de: 'Andere über uns',
	en: 'Others about us',
};

export const pagination = {
	de: 'Paginierung',
	en: 'Pagination',
};

export const jobPostingLocationLabel = {
	de: ['Keine Standorte', 'Standort', 'Standorte'],
	en: ['No locations', 'location', 'locations'],
};

export const jobsOpenPositions = {
	de: 'Offene Stellen',
	en: 'Open positions',
};

export const jobsContactperson = {
	de: 'Ansprechpartner',
};

export const jobContactperson = {
	de: 'Ihr Ansprechpartner',
	en: 'Your contact person',
};

export const telPlaceholder = {
	de: '+49 1234 5678 9',
	en: '+49 1234 5678 9',
};

export const personsRechtsanwaelteTitle = {
	de: 'Rechtsanwälte',
	en: 'Lawyers',
};

export const personsRechtsanwaelteSlug = {
	de: 'rechtsanwaelte',
	en: 'lawyers',
};

export const personsWirtschaftsprueferTitle = {
	de: 'Wirtschaftsprüfer',
	en: 'Auditors',
};

export const personsWirtschaftsprueferSlug = {
	de: 'wirtschaftsprüfer',
	en: 'auditors',
};

export const personsSteuerberaterTitle = {
	de: 'Steuerberater',
	en: 'Tax consultants',
};

export const personsSteuerberaterSlug = {
	de: 'steuerberater',
	en: 'taxconsultants',
};

export const personsSearchName = {
	de: 'Name oder Suchwort',
	en: 'Name or search term',
};

export const personsSearchCompetencies = {
	de: 'Alle Kompetenzen',
	en: 'All competencies',
};

export const personsSearchSubmitText = {
	de: 'Suchen',
	en: 'Search',
};

export const personProfile = {
	de: 'Profil',
	en: 'Profile',
};

export const personArticlesSlug = {
	de: 'beitraege',
	en: 'article',
};

export const personPublicationsSlug = {
	de: 'veroeffentlichungen',
	en: 'publications',
};

export const personEvents = {
	de: 'Alle Veranstaltungen',
	en: 'All events',
};

export const personEventsWith = {
	de: 'Alle Veranstaltungen mit {person}',
	en: 'All events with {person}',
};

export const personEventsSlug = {
	de: 'veranstaltungen',
	en: 'events',
};

export const personEventsPast = {
	de: 'Vergangene Veranstaltungen',
	en: 'Past events',
};

export const personEventsUpcoming = {
	de: 'Kommende Veranstaltungen',
	en: 'Upcoming events',
};

export const personBackTo = {
	de: 'Zurück zu {person}',
	en: 'Back to {person}',
};

export const competencyEventsSlug = {
	de: 'veranstaltungen',
	en: 'events',
};

export const competencyEventsTitle = {
	de: 'Veranstaltungen',
	en: 'Events',
};

export const competencyArticlesSlug = {
	de: 'beitraege',
	en: 'articles',
};

export const competencyArticlesTitle = {
	de: 'Beiträge',
	en: 'Articles',
};

export const search = {
	de: 'Suche',
	en: 'Search',
};

export const searchSubmitText = {
	de: 'Suchen',
	en: 'Search',
};

export const searchInputLabel = {
	de: 'Suchbegriff',
	en: 'Search term',
};

export const locationContact = {
	de: 'Kontakt',
	en: 'Contact',
};

export const locationOpeningHours = {
	de: 'Öffnungszeiten',
	en: 'Opening hours',
};

export const locationGettingThere = {
	de: 'Anfahrt',
	en: 'Getting there',
};

export const locationParking = {
	de: 'Parken',
	en: 'Parking',
};

export const locationTeam = {
	de: 'Team',
};

export const ariaLabelNavigationMain = {
	de: 'Haupt',
	en: 'Main',
};

export const ariaLabelNavigationSecondary = {
	de: 'Unter',
	en: 'Sub',
};

export const ariaLabelFooterNav = {
	de: 'Footer',
	en: 'Footer',
};
