import { LitElement, html } from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import {
	close as closeTranslation,
} from '../foundation/languages.js';
import {
	close as closeSymbol,
} from '../foundation/symbols.js';

const { lang } = document.documentElement;

/* eslint-disable dot-notation */
const translations = {
	close: closeTranslation[lang] ?? closeTranslation['de'],
};

const symbols = {
	close: closeSymbol,
};

class MFlyoutList extends LitElement {
	static properties = {
		open: { type: Boolean },
		data: {
			type: Object,
			attribute: false,
		},
		selectedItemString: {
			type: String,
			attribute: 'selected-item',
		},
	};

	touchMoveYStart = null;

	touchMoveXStart = null;

	touchMoveYDelta = null;

	touchMoveYDirectionDown = false;

	touchMoveScrollLockY = null;

	/** @returns {array} */
	get items() {
		return this.data.items;
	}

	get selectedItem() {
		const { selectedItemString } = this;
		return this.items.find((item) => item.slug === selectedItemString);
	}

	get firstItem() {
		return this.items.at(0);
	}

	// Event Listener
	#onBackdropClick(event) {
		if (event.target === this.backdropElement) {
			this.dialogElement.dispatchEvent(new Event('close'));
		}
	}

	#onClose(event) {
		// Close event of this.dialogElement
		event.preventDefault();
		this.close();
	}

	onItemClick(slug) {
		this.setAttribute('selected-item', slug);
	}

	// Elements
	get dialogElement() {
		return this.renderRoot?.querySelector('dialog');
	}

	get backdropElement() {
		return this.renderRoot?.querySelector('.backdrop');
	}

	get buttonCloseElement() {
		return this.renderRoot?.querySelector('.button-close');
	}

	get contentElement() {
		return this.renderRoot?.querySelector('.content');
	}

	connectedCallback() {
		super.connectedCallback();
		if (!window.matchMedia('(min-width: calc(640em / 16)').matches) {
			this.addEventListener('touchstart', (event) => {
				const { contentElement } = this;

				const itemElement = contentElement?.querySelector('.item');
				if (this.hasAttribute('open') && itemElement.scrollTop <= 0) {
					this.touchMoveYStart = event.touches[0].clientY;
					this.touchMoveXStart = event.touches[0].clientX;
					this.touchMoveScrollLockY = null;
				}
			}, {
				passive: true,
			});
			window.addEventListener('touchmove', (event) => {
				const { contentElement, touchMoveYStart, touchMoveXStart } = this;
				const itemElement = contentElement?.querySelector('.item');

				if (this.hasAttribute('open') && touchMoveYStart !== null && itemElement && itemElement.scrollTop <= 0) {
					const { clientY, clientX } = event.touches[0];
					const touchMoveYDelta = touchMoveYStart - clientY;
					this.touchMoveYDirectionDown = this.touchMoveYDelta > touchMoveYDelta;
					this.touchMoveYDelta = touchMoveYDelta;
					this.touchMoveXDelta = touchMoveXStart - clientX;

					if (this.touchMoveScrollLockY === null) {
						if (Math.abs(this.touchMoveYDelta) > Math.abs(this.touchMoveXDelta)) {
							this.touchMoveScrollLockY = true;
						} else {
							this.touchMoveScrollLockY = false;
						}
					}

					if (contentElement && this.touchMoveScrollLockY === true) {
						if (this.touchMoveYDelta < 0) {
							itemElement.scrollTop = 0;
							itemElement.style.overflow = 'hidden';
							contentElement.style.translate = `0 ${Math.abs(this.touchMoveYDelta) ** 0.8}px`;
						} else {
							contentElement.style.translate = null;
						}
					}
				}
			}, {
				passive: true,
			});
			window.addEventListener('touchend', () => {
				const { contentElement } = this;
				const itemElement = contentElement?.querySelector('.item');

				if (itemElement) {
					itemElement.style.overflow = null;
				}
				if (this.touchMoveYDelta !== null
					&& this.touchMoveYDelta < 0
					&& this.touchMoveScrollLockY === true) {
					// reset contentElement with animation
					contentElement.animate({
						translate: '0 0',
					}, {
						duration: 300,
						easing: 'ease-out',
					}).addEventListener('finish', () => {
						contentElement.style.translate = null;
					});

					if (this.touchMoveYDelta <= -40 && this.touchMoveYDirectionDown) {
						this.close();
					}
				}
				this.touchMoveYStart = null;
				this.touchMoveYDelta = null;
			}, {
				passive: true,
			});
		}
	}

	// Actions
	close() {
		if (this.hasAttribute('open')) {
			const translateContent = window.matchMedia('(min-width: calc(640em / 16)').matches
				? '100% 0' : '0 100%';

			this.contentElement.animate({
				translate: translateContent,
			}, {
				duration: 150,
				easing: 'ease-in',
			});
			this.buttonCloseElement?.animate({
				scale: 0.5,
				opacity: 0,
			}, {
				duration: 150,
				easing: 'ease-in',
			});
			const animation = this.backdropElement.animate({
				opacity: 0,
			}, {
				duration: 150,
				easing: 'ease-in-out',
			});

			animation.addEventListener('finish', () => {
				this.toggleAttribute('open', false);
				document.documentElement.style.overflow = null;
				this.dialogElement.close();

				this.dispatchEvent(new CustomEvent('close'));

				this.targetButton?.focus();
			});
		}
	}

	scrollSelectedItemIntoView() {
		const navElement = this.renderRoot.querySelector('.nav');
		const navButtonSelectedElement = navElement?.querySelector('button[data-selected]');
		if (navButtonSelectedElement) {
			const isInView = (navButtonSelectedElement.offsetLeft + navButtonSelectedElement.offsetWidth)
				> navElement.offsetWidth;
			if (navButtonSelectedElement && isInView) {
				navElement.scroll({
					left: navButtonSelectedElement.offsetLeft,
					behavior: 'smooth',
				});
			}
		}
	}

	showModal() {
		const { selectedItem, firstItem } = this;

		if (this.dialogElement && !this.hasAttribute('open')) {
			const translateContent = window.matchMedia('(min-width: calc(640em / 16)').matches
				? ['100% 0', '0 0'] : ['0 100%', '0 0'];

			this.targetButton = document.activeElement;
			this.toggleAttribute('open', true);
			document.documentElement.style.overflow = 'hidden';
			this.dialogElement.showModal();
			this.dialogElement.focus();
			this.backdropElement.animate({
				opacity: [0, 1],
			}, {
				duration: 500,
				easing: 'ease-in-out',
			});
			this.buttonCloseElement?.animate({
				scale: [0.5, 1],
				opacity: [0, 1],
			}, {
				duration: 250,
				delay: 100,
				easing: 'ease-in',
				fill: 'backwards',
			});
			this.contentElement.animate({
				translate: translateContent,
			}, {
				duration: 250,
				fill: 'backwards',
				easing: 'ease-out',
			});

			setTimeout(() => {
				this.scrollSelectedItemIntoView();
			}, 200);

			if (typeof selectedItem !== 'object') {
				this.setAttribute('selected-item', firstItem.slug);
			}
		}
	}

	render() {
		const { selectedItem } = this;

		return html`
			<link rel="stylesheet" href="/assets/css/m-flyout-list.${BUILT_AT}.css">
			<dialog tabindex="0" @close=${this.#onClose}>
				<div class="backdrop" @click=${this.#onBackdropClick}>
				</div>
				<button class="button-close" @click="${this.close}" aria-label="${translations.close}">${unsafeHTML(symbols.close)}</button>
				<div class="content">
					<nav class="nav">
						${this.renderNav()}
					</nav>
					<div class="item">
						${selectedItem ? this.renderItem(selectedItem) : null}
					</div>
				</div>
			</dialog>
		`;
	}
}

// Define the custom element
if (!customElements.get('m-flyout-list')) {
	customElements.define('m-flyout-list', MFlyoutList);
}

export default MFlyoutList;
