// This file is automatically generated by generate-symbols-js.php
// Last build: 2024-09-05T16:14:01+02:00

export const arrowNe = '<svg xmlns="http://www.w3.org/2000/svg" width="284" height="284" viewBox="0 0 284 284"><polygon fill="#000" points="189.49 80.52 112.22 80.52 112.22 98.06 177.71 98.06 64.45 211.33 76.78 223.65 189.49 110.94 189.49 174.35 207.03 174.35 207.03 98.06 207.03 80.52"/></svg>';

export const chevronE = `<?xml version="1.0" encoding="UTF-8"?>
<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46">
  <defs>
    <style>
      .cls-1 {
        fill: #1d1d1b;
        stroke-width: 0px;
      }
    </style>
  </defs>
  <polygon class="cls-1" points="110.53 89.4 171.15 150.03 111.3 209.89 125.06 223.65 184.92 163.79 198.68 150.03 184.92 136.27 124.29 75.64 110.53 89.4"/>
</svg>`;

export const close = '<svg xmlns="http://www.w3.org/2000/svg" width="284" height="284" viewBox="0 0 284 284"><g transform="rotate(45 36.16 185.84)"><polygon points="76 0 84 0 84 160 76 160"/><polygon points="0 76 160 76 160 84 0 84"/></g></svg>';

export const linkedin = `<?xml version="1.0" encoding="UTF-8"?>
<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46">
  <defs>
    <style>
      .cls-1 {
        fill: #1d1d1b;
        stroke-width: 0px;
      }
    </style>
  </defs>
  <path class="cls-1" d="M102.3,213.78h-29.75v-96.06h29.75v96.06ZM87.28,105.14c-9.72,0-17.59-7.94-17.59-17.73s7.88-17.73,17.59-17.73,17.59,7.94,17.59,17.73-7.87,17.73-17.59,17.73ZM213.78,213.78h-29.61v-50.43c0-13.83-5.25-21.55-16.2-21.55-11.91,0-18.12,8.04-18.12,21.55v50.43h-28.53v-96.06h28.53v12.94s8.58-15.87,28.96-15.87,34.96,12.44,34.96,38.18v60.82Z"/>
</svg>`;

export const plus = '<svg xmlns="http://www.w3.org/2000/svg" width="284" height="284" viewBox="0 0 284 284"><g fill="none" fill-rule="evenodd"><rect width="6" height="160" x="139" y="62" fill="#000"/><rect width="160" height="6" x="62" y="139" fill="#000"/></g></svg>';

export const quotationMarks = '<svg xmlns="http://www.w3.org/2000/svg" width="172" height="143" viewBox="0 0 172 143"><path fill="#1D1D1B" d="M67.78 67.41C67.78 67.41 72.7 148.49.23 142.15L.47 112.35C39 108.09 34.01 67.41 34.01 67.41L-7.10542736e-15 67.41-7.10542736e-15 5.68434189e-14 67.79 5.68434189e-14 67.79 67.41 67.78 67.41ZM171.04 67.56C171.04 67.56 175.96 148.64 103.49 142.3L103.73 112.5C142.26 108.24 137.27 67.56 137.27 67.56L103.26 67.56 103.26.15 171.05.15 171.05 67.56 171.04 67.56Z"/></svg>';

export const xing = `<?xml version="1.0" encoding="UTF-8"?>
<svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283.46 283.46">
  <defs>
    <style>
      .cls-1 {
        fill: #1d1d1b;
        stroke-width: 0px;
      }
    </style>
  </defs>
  <path class="cls-1" d="M102.18,181.03c2.62,0,4.86-1.58,6.72-4.76,17.02-30.19,25.88-45.9,26.57-47.14l-16.95-29.57c-1.79-3.1-4.07-4.65-6.82-4.65h-24.71c-1.59,0-2.69.52-3.31,1.55-.76,1.03-.72,2.31.1,3.82l16.64,28.84c.07.07.07.1,0,.1l-26.16,46.32c-.69,1.31-.69,2.55,0,3.72.69,1.18,1.76,1.76,3.2,1.76h24.71Z"/>
  <path class="cls-1" d="M215.59,61.2h-24.92c-2.69,0-4.89,1.55-6.62,4.65-35.36,62.72-53.66,95.19-54.9,97.39l35.05,64.31c1.65,3.1,3.93,4.65,6.82,4.65h24.71c1.52,0,2.62-.52,3.31-1.55.69-1.18.65-2.45-.1-3.83l-34.73-63.58v-.1l54.59-96.56c.76-1.45.76-2.72,0-3.82-.62-1.04-1.69-1.55-3.21-1.55Z"/>
</svg>`;
