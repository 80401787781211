class MCompetencySpiderDetail {
	/** @type {HTMLDetailsElement} */
	element;

	/** @param {HTMLDetailsElement} element */
	constructor(element) {
		this.element = element;

		window.addEventListener('hashchange', this.openElement.bind(this));
		window.addEventListener('load', this.openElement.bind(this));
	}

	openElement() {
		const { element } = this;
		if (window.location.hash !== '') {
			const target = document.querySelector(window.location.hash);
			if (target === element) {
				target.open = true; // Ensure the details element expands when linked to
			}
		}
	}
}

// Query only details elements with this class!
document.querySelectorAll('details.m-competency-spider-detail').forEach((_) => new MCompetencySpiderDetail(_));
