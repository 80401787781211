import {
	html,
	render,
	nothing,
} from 'lit';

class MPublications {
	_columns = 0;

	set columns(val) {
		this._columns = parseInt(val, 10);
	}

	get columns() {
		/* if (window.matchMedia('not (min-width: calc(928em / 16))').matches) {
			return 1;
		} */
		// quickfix
		if (window.matchMedia('(max-width: 640px)').matches) {
			return 1;
		}else if (window.matchMedia('(max-width: 927px)').matches) {
			return 2;
		}
		return this._columns;
	}

	/** @param {HTMLDivElement} element */
	constructor(element) {
		this.currentIndex = 1;
		this.columns = 3;

		this.scrollContainerElement = element.querySelector('.m-publications__list');
		this.listElement = element.querySelector('.m-publications__list');
		this.aPaginationElement = element.querySelector('.a-pagination');

		const setSnapPoints = () => {
			const { columns } = this;
			// quickfix
			if (window.matchMedia('(max-width: 640px)').matches) {
				const elements = this.listElement.querySelectorAll('li');
				this.snapPoints = []
				for (let i = 0; i < elements.length; i += 1) {
					this.snapPoints.push(elements[i]);
				}
			}else if (window.matchMedia('(max-width: 927px)').matches) {
				const elements = this.listElement.querySelectorAll('li');
				this.snapPoints = []
				for (let i = 0; i < elements.length; i += 2) {
					this.snapPoints.push(elements[i]);
				}
			}else{
				this.snapPoints = [...this.listElement.querySelectorAll(`li:nth-child(${columns}n - ${columns - 1})`)];
			}
			// console.log(this.snapPoints);
		};

		setSnapPoints();

		this.scrollContainerElement.addEventListener('scroll', () => {
			if (!window.matchMedia('(max-width: 640px)').matches && window.matchMedia('(max-width: 927px)').matches) {
				let scrollposition = this.listElement.scrollLeft;
				if(scrollposition / this.listElement.clientWidth + 1 < 1.5){
					this.currentIndex = 1;
				}else{
					this.currentIndex = Math.ceil(scrollposition / this.listElement.clientWidth + 1);
				}
			}else{
				this.currentIndex = this.getCurrentSnappedIndex() + 1;
			}
			this.render();
		}, {
			passive: true,
		});
		window.addEventListener('resize', () => {
			setSnapPoints();
			this.render();
		});

		this.render();
	}

	getCurrentSnappedIndex() {
		const { scrollContainerElement, snapPoints } = this;
		const { scrollLeft } = scrollContainerElement;

		// Find the index of the child that is closest to the current scroll position
		let closestChildIndex = 0;
		let smallestDifference = Infinity;

		snapPoints.forEach((child, index) => {
			const difference = Math.abs(scrollLeft - child.offsetLeft);
			// console.log(difference, child);
			if (difference < smallestDifference) {
				smallestDifference = difference;
				closestChildIndex = index;
			}
		});

		return closestChildIndex;
	}

	render() {
		const { currentIndex, listElement } = this;
		const snapPointsCount = this.snapPoints.length;
		const paginationArray = Array.from({ length: snapPointsCount }, (_, index) => index + 1);

		const onPaginationClick = (event) => {
			const { target } = event;
			const index = parseInt(target.dataset.index, 10);
			// quickfix
			let scrollTargetElement;
			if (window.matchMedia('(max-width: 640px)').matches) {
				scrollTargetElement = listElement.querySelector(`li:nth-child(${index})`);
				listElement.scroll({
					left: scrollTargetElement.offsetLeft,
					behavior: 'smooth',
				});
			}else if (window.matchMedia('(max-width: 927px)').matches) {
				const nthChild = index * 2 - 1;
				const scrollTargetDistance = listElement.querySelector(`li:nth-child(${nthChild})`).offsetLeft;
				listElement.scroll({
					left: scrollTargetDistance,
					behavior: 'smooth',
				});
			}else{
				const nthChild = Math.round(index * snapPointsCount) - snapPointsCount + 1;
				scrollTargetElement = listElement.querySelector(`li:nth-child(${nthChild})`);
				listElement.scroll({
					left: scrollTargetElement.offsetLeft,
					behavior: 'smooth',
				});
			}
			// console.log(nthChild, scrollTargetElement);
		};

		if (snapPointsCount > 1) {
			render(html`
				${paginationArray.map((count) => html`
					<li>
						<button
							class="a-button"
							data-kind="tertiary"
							data-shape="squared"
							data-size="small"
							data-selected="${count === currentIndex ? true : nothing}"
							aria-pressed="${count === currentIndex ? 'true' : nothing}"
							data-index="${count}"
							@click="${onPaginationClick}"
						>
							${count}
						</button>
					</li>
				`)}
			`, this.aPaginationElement);
		} else {
			this.aPaginationElement.innerHTML = '';
		}
	}
}

document.querySelectorAll('.m-publications[data-paginate]').forEach((_) => new MPublications(_));
