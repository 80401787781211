/** @typedef {import("./m-flyout-list-persons").default} MFlyoutListPersons */

class MPersonGroups {
	/**
	 * @param {HTMLDivElement} element
	 */
	constructor(element) {
		/** @type {HTMLScriptElement} */
		const dataElement = element.querySelector('.m-person-groups__data');

		if (dataElement) {
			/** @type {MFlyoutListPersons} */
			this.mFlyoutList = document.createElement('m-flyout-list-persons');

			/** @type {HTMLHeadingElement} */
			this.headingElement = element.querySelector('h2');

			this.aThumbPersonElements = element.querySelectorAll('.a-thumb-person');

			// Event listener
			this.headingElement?.setAttribute('role', 'button');
			this.headingElement?.addEventListener('click', () => {
				this.mFlyoutList.showModal();
			});
			this.aThumbPersonElements.forEach(
				/** @param {HTMLAnchorElement} aThumbPersonElement */ (aThumbPersonElement) => {
					aThumbPersonElement.addEventListener('click', (event) => {
						event.preventDefault();
						this.mFlyoutList.setAttribute('selected-item', aThumbPersonElement.dataset.slug);
						this.mFlyoutList.showModal();
					});
				},
			);

			// Init
			this.mFlyoutList.data = JSON.parse(dataElement.innerText);
			element.appendChild(this.mFlyoutList);
		}
	}
}

document.querySelectorAll('.m-person-groups').forEach((_) => new MPersonGroups(_));
